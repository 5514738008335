import type {
	DeepPartial,
	NubeSDKRuntime,
	NubeSDKRuntimeListener,
	NubeSDKRuntimeState,
} from "@tiendanube/nube-sdk-internal-types";
import { deepMerge } from "./deep-merge";

const FULL_REPLACE_PATHS = ["location.page.data"];

const EMPTY_STATE: DeepPartial<NubeSDKRuntimeState> = {
	cart: {},
	location: {},
	store: {},
	apps: {},
};

export function createSDK(initialData: NubeSDKRuntimeState): NubeSDKRuntime {
	const handlers = new Map<string, NubeSDKRuntimeListener[]>();
	let data = { ...EMPTY_STATE, ...structuredClone(initialData) };

	return {
		on(event, listener) {
			const listeners = handlers.get(event) || [];
			handlers.set(event, [...listeners, listener]);
		},

		off(event, listener) {
			const listeners = handlers.get(event);

			if (listeners === undefined) return;

			handlers.set(
				event,
				listeners.filter((fn) => fn !== listener),
			);
		},

		send(appid, event, modifier) {
			if (modifier) {
				const result = deepMerge(data, modifier(data), FULL_REPLACE_PATHS);
				if (result) data = result;
			}

			const eventListeners = handlers.get(event);

			if (eventListeners)
				for (const fn of eventListeners) fn(data, event, appid);

			const globalListeners = handlers.get("*");

			if (globalListeners)
				for (const fn of globalListeners) fn(data, event, appid);
		},

		getState() {
			return structuredClone(data);
		},
	};
}
